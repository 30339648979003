
import { defineComponent, onMounted, reactive, ref } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import {
  getFacilityBySpace,
  statusDictionary,
  typeDictionary,
  modeDictionary,
  overlapTypeDictionary,
} from "@/core/services/api/facilities";
import {
  FacilityType,
  FacilityStatus,
} from "@/core/services/responsesModel/facilityExtend";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import moment from "moment";
import { Modal } from "bootstrap";
import GenerallyAdded from "./components/GenerallyAdded.vue";
import FlexibleAdded from "./components/FlexibleAdded.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Datatable,
    GenerallyAdded,
    FlexibleAdded,
  },
  emits: ["setTableData"],
  setup() {
    const currentFacility: any = reactive({});
    const router = useRouter();
    const spaceFacility: any = reactive([]);
    const facilityQuantity = ref(0);
    let flexibleAddedModal;
    let generallyAddedModal;
    let addFacilityListModal;
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "排序",
        key: "displayOrder",
        sortable: true,
      },
      {
        name: "設施名稱",
        key: "name",
        sortable: true,
      },
      {
        name: "設施類型",
        key: "category",
        sortable: true,
      },
      {
        name: "模式",
        key: "mode",
        sortable: true,
      },
      {
        name: "狀態",
        key: "status",
        sortable: true,
      },
      {
        name: "座位數量",
        key: "quota",
        sortable: true,
      },
      {
        name: "操作",
        key: "operate",
        sortable: true,
      },
    ]);
    const isLoadind = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    const handelGetFacilityBySpace = async () => {
      let facility = await getFacilityBySpace(currentSpaceGuid!);

      return facility;
    };

    const setTableData = async () => {
      let facility: any = (await handelGetFacilityBySpace()).map(
        (o) => o.facility
      );
      // 過濾出設施
      facility = facility.filter((o) => o.type == FacilityType.Facility);
      // 過濾掉特殊通行的facility
      facility = facility.filter((o) => o.status !== FacilityStatus.Hidden);
      tableData.splice(0);
      facility.forEach((o) => {
        tableData.push({
          facility: o,
          // 排序
          displayOrder: o.displayOrder,
          // 設施名稱
          name: o.name,
          // 設施類型
          category: o.category,
          // 模式
          mode: modeDictionary[o.mode],
          // 狀態
          status: statusDictionary[o.status],
          // 座位數量
          quota: o.quota,
        });
      });
      spaceFacility.splice(0, spaceFacility.length, ...facility);
      facilityQuantity.value = tableData.length;
      tableData.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      initTableData.splice(0, initTableData.length, ...tableData);
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const openGenerallyAdded = () => {
      addFacilityListModal.hide();
      generallyAddedModal.show();
    };

    const openFlexibleAdded = () => {
      addFacilityListModal.hide();
      flexibleAddedModal.show();
    };

    const closeGenerallyAddedModal = () => {
      generallyAddedModal.hide();
    };

    const closeFlexibleAddedModal = () => {
      flexibleAddedModal.hide();
    };

    const toConfigePage = () => {
      router.push({ path: `facilityConfige/${currentFacility.facility.guid}` });
    };

    const toThemePhoto = () => {
      router.push({
        path: `facilityThemePhoto/${currentFacility.facility.guid}`,
      });
    };

    const toSeatList = () => {
      router.push({
        path: `seatList/${currentFacility.facility.guid}`,
      });
    };

    const toReserveList = () => {
      router.push({
        path: `reserveList/${currentFacility.facility.guid}`,
      });
    };

    const setCurrentFacility = (item) => {
      Object.assign(currentFacility, item);
    };

    onMounted(() => {
      addFacilityListModal = new Modal(
        document.getElementById("addFacilityList-modal")
      );
      generallyAddedModal = new Modal(
        document.getElementById("generallyAdded-modal")
      );
      flexibleAddedModal = new Modal(
        document.getElementById("flexibleAdded-modal")
      );
    });

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      tableData,
      tableSearch,
      tableHeader,
      search,
      isLoadind,
      openGenerallyAdded,
      facilityQuantity,
      setTableData,
      closeGenerallyAddedModal,
      openFlexibleAdded,
      closeFlexibleAddedModal,
      spaceFacility,
      toConfigePage,
      setCurrentFacility,
      toThemePhoto,
      toSeatList,
      toReserveList,
    };
  },
});
