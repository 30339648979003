
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import localstorage from "@/core/services/LocalstorageService";
import { getSupportedTaxes } from "@/core/services/api/taxes";
import { addFacility } from "@/core/services/api/facilities";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  props: ["facilityQuantity", "spaceFacility"],
  setup(props, { emit }) {
    const facilityOption: any = reactive([]);
    const amountLabel = ref("價格");
    const taxDisabled = ref(false);
    const amountDisabled = ref(false);
    const displayOrder = computed(() => props.facilityQuantity + 1);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const defaultRequest = reactive({
      space: currentSpaceGuid,
      name: "",
      category: "",
      type: "Facility",
      mode: "Standard",
      status: "Public",
      description: "",
      openingHoursDescription: "",
      location: "",
      displayOrder: 0,
      usageNotice: "",
      priceTag: "",
      timeSpan: 0,
      currency: "Free",
      amount: 0,
      tax: "",
      overLapTargets: [],
    });
    // 所有重疊或包場的類型
    const overlapTypeDictionary = [
      { value: "Overlap", text: "重疊" },
      { value: "Whole", text: "包場" },
    ];
    const taxOptions = [{ value: "noTax", text: "不需稅率" }];
    //貨幣單位options
    const currencyDictionaryOptions = [
      { value: "Free", text: "免費" },
      { value: "Point", text: "點數" },
      { value: "TWD", text: "新台幣" },
    ];
    //時間單位options
    const timeSpanOptions = [
      { value: 30, text: "30分鐘" },
      { value: 60, text: "1小時" },
    ];
    //狀態options
    const statusDictionaryOptions = [
      { value: "Public", text: "開放" },
      { value: "Private", text: "關閉" },
      { value: "Protected", text: "公開(不可預約)" },
    ];
    //模式options
    const newModeDictionaryOptions = [
      { value: "Standard", text: "標準" },
      { value: "PrivateOffice", text: "私人辦公室" },
    ];
    //類型options
    const categoriesOptions =
      localstorage.getCurrentSpaces()?.space.facilitySetting.facilityCategories;
    const formRef = ref<null | HTMLFormElement>(null);
    const value = ref("");
    const formData = reactive({
      name: "",
      category: "",
      mode: "",
      status: "",
      timeSpan: "",
      currency: "",
      tax: "",
      amount: 0,
      openingHoursDescription: "",
      location: "",
      description: "",
      overlapOrCharter: "Overlap",
      overLapTargets: [],
    });

    const rules = {
      name: [{ required: true, message: "請輸入設施名稱", trigger: "blur" }],
      category: [
        { required: true, message: "請選擇設施類型", trigger: "change" },
      ],
      overLapTargets: [
        { required: true, message: "請選擇設施", trigger: "change" },
      ],
      status: [
        { required: true, message: "請選擇設施狀態", trigger: "change" },
      ],
      timeSpan: [
        { required: true, message: "請選擇設施時間單位", trigger: "change" },
      ],
      currency: [
        { required: true, message: "請選擇設施運作模式", trigger: "change" },
      ],
      tax: [{ required: true, message: "請選擇設施稅單位", trigger: "change" }],
      amount: [{ required: true, message: "請輸入設施價格", trigger: "blur" }],
    };

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增設施嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request: any;
              let defaultRequestCopy = JSON.parse(
                JSON.stringify(defaultRequest)
              );
              request = Object.assign(defaultRequestCopy, formData);
              request.displayOrder = displayOrder.value;
              await addFacility(request);
              await Swal.fire("新增成功!", "", "success");
              Object.assign(formData, defaultRequest);
              
              formRef.value!.resetFields();
              emit("setTableData");
              emit("closeFlexibleAddedModal");
            }
          });
        }
      });
    };

    const setSupportedTaxes = async () => {
      let supportedTaxes = await getSupportedTaxes();
      supportedTaxes.forEach((taxe) => {
        taxOptions.push({ value: taxe.guid, text: taxe.description });
      });
    };

    const changeCurrency = () => {
      amountLabel.value = "價格";
      formData.amount = 0;
      taxDisabled.value = false;
      amountDisabled.value = false;
      if (formData.currency == "Free") {
        amountLabel.value = "價格";
        formData.tax = "noTax";
        formData.amount = 0;
        taxDisabled.value = true;
        amountDisabled.value = true;
      } else if (formData.currency == "Point") {
        amountLabel.value = "點數";
        formData.tax = "noTax";
        taxDisabled.value = true;
      }
    };

    const setFacilityOption = () => {
      facilityOption.splice(0);
      if (JSON.stringify(props.spaceFacility) !== "{}") {
        props.spaceFacility.forEach((o) => {
          if (o.mode !== "Overlap") {
            facilityOption.push({
              value: o.guid,
              text: o.name,
            });
          }
        });
      }
    };

    watch(
      props.spaceFacility,
      () => {
        setFacilityOption();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    onMounted(() => {
      let flexibleAddedElement: any = document.getElementById(
        "flexibleAdded-modal"
      );
      flexibleAddedElement.addEventListener("hidden.bs.modal", function () {
        Object.assign(formData, defaultRequest);
        formRef.value!.resetFields();
        amountLabel.value = "價格";
        taxDisabled.value = false;
        amountDisabled.value = false;
      });
    });

    const init = async () => {
      await setSupportedTaxes();
    };
    init();

    return {
      formData,
      rules,
      onSubmit,
      value,
      formRef,
      categoriesOptions,
      newModeDictionaryOptions,
      statusDictionaryOptions,
      timeSpanOptions,
      currencyDictionaryOptions,
      taxOptions,
      changeCurrency,
      taxDisabled,
      amountDisabled,
      amountLabel,
      overlapTypeDictionary,
      setFacilityOption,
      facilityOption,
    };
  },
});
