export interface FacilityExtend {
    "facility": Facility,
    "charges": Array<Charge>
}

export enum FacilityType {
    Facility="Facility", Property="Property"
}

export enum FacilityMode {
    Standard="Standard", PrivateOffice="PrivateOffice", Overlap="Overlap"
}

export enum FacilityStatus {
    Public="Public", Protected="Protected", Private="Private", Hidden="Hidden"
}

export interface FacilityInformation {
    "icon": string,
    "title": string,
    "content": string
}

export interface FacilityTheme {
    "coverPhoto": string,
    "images": Array<string>,
    "locationImages": Array<string>
}

export interface FacilitySetting {
    "availableDays": number,
    "invitationNumber": number,
    "minuteToEnter": number,
    "minuteToCancel": number,
    "minuteToExtend": number,
    "minuteToCheckOut": number,
    "autoRelease": boolean,
    "delayCaptureInDays": number,
    "canDoorAccess": boolean,
    "canIotFetch": boolean,
    "canDistribute": boolean,
    "canInvite": boolean,
    "canUserCheckOut": boolean,
    "canUserFeedback": boolean
}

export interface Facility {
    "type": FacilityType,
    "mode": FacilityMode,
    "status": FacilityStatus,
    "information": Array<FacilityInformation>,
    "theme": FacilityTheme,
    "setting": FacilitySetting,
    "guid": string,
    "space": string,
    "id": string,
    "name": string,
    "category": string,
    "createTime": string,
    "description": string,
    "openingHoursDescription": string,
    "location": string,
    "quota": number,
    "displayOrder": number,
    "usageNotice": string,
    "priceTag": string
}

export enum ChargeType {
    Free, Point, CreditCard
}

export enum ChargeCurrency {
    Free, Point, TWD
}

export enum TaxCode {
    T, N, F
}

export interface Tax {
    "code": TaxCode,
    "guid": string,
    "rate": number,
    "description": string,
    "createTime": string
}

export enum RuleType {
    Close, Open, FixedOpen
}

export interface Rule {
    "type": RuleType,
    "guid": string,
    "target": string,
    "expression": string,
    "timeSpan": number,
    "beginTime": string,
    "endTime": string,
    "createTime": string
}


export interface Charge {
    "type": ChargeType,
    "currency": ChargeCurrency,
    "tax": Tax,
    "rule": Rule,
    "guid": string,
    "target": string,
    "amount": number,
    "includeTax": boolean,
    "beginTime": string,
    "endTime": string,
    "createTime": string
}

export const typeMap = {
    Property:"設備",
    Facility:"設施"
}