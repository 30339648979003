import ApiService from "@/core/services/ApiService";
import { Tax } from "@/core/services/responsesModel/tax"

export function getSupportedTaxes():Promise<Array<Tax>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Taxes/QuerySupported`,{}).then(res => {
            resolve(res.data.taxes)
        }).catch(err => {
            reject(err)
        })
    })
}
