<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col d-flex">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <button
            class="btn btn-success ms-3"
            data-bs-toggle="modal"
            data-bs-target="#addFacilityList-modal"
          >
            新增
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-displayOrder="{ row: tableData }">
          {{ tableData.displayOrder }}
        </template>
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-category="{ row: tableData }">
          {{ tableData.category }}
        </template>
        <template v-slot:cell-mode="{ row: tableData }">
          {{ tableData.mode }}
        </template>
        <template v-slot:cell-status="{ row: tableData }">
          {{ tableData.status }}
        </template>
        <template v-slot:cell-quota="{ row: tableData }">
          {{ tableData.quota }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentFacility(tableData)"
              class="el-dropdown-link text-primary"
              
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="toConfigePage()"
                  >基本資訊與設定</el-dropdown-item
                >
                <el-dropdown-item @click="toThemePhoto()"
                  >主題照片</el-dropdown-item
                >
                <el-dropdown-item @click="toSeatList()"
                  >座位列表</el-dropdown-item
                >
                <el-dropdown-item @click="toReserveList()"
                  >特殊保留</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
    <!-- 新增設施modal -->
    <div
      class="modal fade"
      id="addFacilityList-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addFacilityList-exampleModalLabel">
              新增設施
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <button class="btn btn-success" @click="openGenerallyAdded()">
                一般新增
              </button>
              <button class="btn btn-success ms-3" @click="openFlexibleAdded()">
                彈性配置新增
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 一般新增設施modal -->
    <div
      class="modal fade"
      id="generallyAdded-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="generallyAdded-exampleModalLabel">
              一般新增設施
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <GenerallyAdded
              :facilityQuantity="facilityQuantity"
              @setTableData="setTableData()"
              @closeGenerallyAddedModal="closeGenerallyAddedModal()"
            ></GenerallyAdded>
          </div>
        </div>
      </div>
    </div>
    <!-- 彈性配置新增設施modal -->
    <div
      class="modal fade"
      id="flexibleAdded-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="flexibleAdded-exampleModalLabel">
              彈性配置新增設施
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <FlexibleAdded
              :spaceFacility="spaceFacility"
              :facilityQuantity="facilityQuantity"
              @setTableData="setTableData()"
              @closeFlexibleAddedModal="closeFlexibleAddedModal()"
            ></FlexibleAdded>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import {
  getFacilityBySpace,
  statusDictionary,
  typeDictionary,
  modeDictionary,
  overlapTypeDictionary,
} from "@/core/services/api/facilities";
import {
  FacilityType,
  FacilityStatus,
} from "@/core/services/responsesModel/facilityExtend";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import moment from "moment";
import { Modal } from "bootstrap";
import GenerallyAdded from "./components/GenerallyAdded.vue";
import FlexibleAdded from "./components/FlexibleAdded.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Datatable,
    GenerallyAdded,
    FlexibleAdded,
  },
  emits: ["setTableData"],
  setup() {
    const currentFacility: any = reactive({});
    const router = useRouter();
    const spaceFacility: any = reactive([]);
    const facilityQuantity = ref(0);
    let flexibleAddedModal;
    let generallyAddedModal;
    let addFacilityListModal;
    const search = ref<string>("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "排序",
        key: "displayOrder",
        sortable: true,
      },
      {
        name: "設施名稱",
        key: "name",
        sortable: true,
      },
      {
        name: "設施類型",
        key: "category",
        sortable: true,
      },
      {
        name: "模式",
        key: "mode",
        sortable: true,
      },
      {
        name: "狀態",
        key: "status",
        sortable: true,
      },
      {
        name: "座位數量",
        key: "quota",
        sortable: true,
      },
      {
        name: "操作",
        key: "operate",
        sortable: true,
      },
    ]);
    const isLoadind = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;

    const handelGetFacilityBySpace = async () => {
      let facility = await getFacilityBySpace(currentSpaceGuid!);

      return facility;
    };

    const setTableData = async () => {
      let facility: any = (await handelGetFacilityBySpace()).map(
        (o) => o.facility
      );
      // 過濾出設施
      facility = facility.filter((o) => o.type == FacilityType.Facility);
      // 過濾掉特殊通行的facility
      facility = facility.filter((o) => o.status !== FacilityStatus.Hidden);
      tableData.splice(0);
      facility.forEach((o) => {
        tableData.push({
          facility: o,
          // 排序
          displayOrder: o.displayOrder,
          // 設施名稱
          name: o.name,
          // 設施類型
          category: o.category,
          // 模式
          mode: modeDictionary[o.mode],
          // 狀態
          status: statusDictionary[o.status],
          // 座位數量
          quota: o.quota,
        });
      });
      spaceFacility.splice(0, spaceFacility.length, ...facility);
      facilityQuantity.value = tableData.length;
      tableData.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      initTableData.splice(0, initTableData.length, ...tableData);
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const openGenerallyAdded = () => {
      addFacilityListModal.hide();
      generallyAddedModal.show();
    };

    const openFlexibleAdded = () => {
      addFacilityListModal.hide();
      flexibleAddedModal.show();
    };

    const closeGenerallyAddedModal = () => {
      generallyAddedModal.hide();
    };

    const closeFlexibleAddedModal = () => {
      flexibleAddedModal.hide();
    };

    const toConfigePage = () => {
      router.push({ path: `facilityConfige/${currentFacility.facility.guid}` });
    };

    const toThemePhoto = () => {
      router.push({
        path: `facilityThemePhoto/${currentFacility.facility.guid}`,
      });
    };

    const toSeatList = () => {
      router.push({
        path: `seatList/${currentFacility.facility.guid}`,
      });
    };

    const toReserveList = () => {
      router.push({
        path: `reserveList/${currentFacility.facility.guid}`,
      });
    };

    const setCurrentFacility = (item) => {
      Object.assign(currentFacility, item);
    };

    onMounted(() => {
      addFacilityListModal = new Modal(
        document.getElementById("addFacilityList-modal")
      );
      generallyAddedModal = new Modal(
        document.getElementById("generallyAdded-modal")
      );
      flexibleAddedModal = new Modal(
        document.getElementById("flexibleAdded-modal")
      );
    });

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      tableData,
      tableSearch,
      tableHeader,
      search,
      isLoadind,
      openGenerallyAdded,
      facilityQuantity,
      setTableData,
      closeGenerallyAddedModal,
      openFlexibleAdded,
      closeFlexibleAddedModal,
      spaceFacility,
      toConfigePage,
      setCurrentFacility,
      toThemePhoto,
      toSeatList,
      toReserveList,
    };
  },
});
</script>

<style>
</style>